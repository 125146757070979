import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "src/components/widgets/Loader";
const Auth = lazy(() => import("./pages/auth"));
const Generator = lazy(() => import("./pages/generator"));
const PriceAlert = lazy(() => import("./pages/priceAlert"));
const Account = lazy(() => import("./pages/account"));
const ForgetPass = lazy(() => import("./pages/reset-pass-pages/forgetPass"));
const NewPass = lazy(() => import("./pages/reset-pass-pages/newPass"));
const NotFound = lazy(() => import("./pages/404"));

export const useRoutes = () => {
  const renderLoader = () => (
    <div className="loader-wr">
      <Loader />
    </div>
  );

  return (
    <Suspense fallback={renderLoader()}>
      <Routes>
        <Route exact path="/login" element={<Auth join={false} />} />
        <Route path="/forget" element={<ForgetPass />} />
        <Route path="/forget/:token" element={<NewPass />} />

        <Route exact path="/" element={<Generator />} />
        <Route path="/price-alert/:tab" element={<PriceAlert />} />
        <Route exact path="/account/:tab" element={<Account />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
