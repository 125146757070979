import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppProvider } from "src/context/AppContext";
import { useRoutes } from "./routes";
// import { Navbar } from "./components/Navbar";
import { PaymentCheck } from "src/components/global-check/payment";
import Nav from "src/components/navigation/Nav_n";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core";
import theme from "src/theme";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  const routes = useRoutes();

  return (
    <AppProvider>
      <Router>
        <ThemeProvider theme={responsiveFontSizes(theme)}>
          <PaymentCheck />
          <Nav />
          <main role="main" className="main-content-wr">
            {routes}
          </main>
          <ToastContainer limit={3} />
        </ThemeProvider>
      </Router>
    </AppProvider>
  );
}

export default App;
