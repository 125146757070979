import React from "react";

export default function Loader() {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "2rem" }}
    >
      <div className="lds-dual-ring"></div>
    </div>
  );
}
