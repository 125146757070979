import React, { useContext, createContext } from "react";
import Loader from "src/components/widgets/Loader";
import { useAuth } from "src/hooks/auth.hook";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const { logout, login, token, ready, userInfo } = useAuth();
  const isAuthenticated = !!token;
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

  if (!ready) {
    return (
      <main role="main" className="main-content-wr">
        <section className="loader-wr">
          <Loader />
        </section>
      </main>
    );
  }

  return (
    <AppContext.Provider
      value={{
        token,
        login,
        logout,
        userInfo,
        isAuthenticated,
        isMobile,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
