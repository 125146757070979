import axios from "axios";
import cookie from "./cookie";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const server = axios.create({
  baseURL: "https://link.fatcoupon.com/",
  timeout: 600000,
});

server.interceptors.request.use(
  (config) => {
    let token = cookie.get("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

server.interceptors.response.use(
  (response) => {
    let res = response;
    if (isLocalhost) {
      console.log(response.data);
    }

    if (undefined !== response.status) {
      res = response.data;
    } else {
      res = JSON.parse(response).data;
    }
    if (response.status !== 200 && response.status !== 201) {
      if (res.errno === 1101 || res.errno === 1100 || res.errno === 1201) {
      } else {
        console.log(res.errmsg || "Error");
      }
      return Promise.reject(new Error(res.errmsg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log(error.response);
    return Promise.reject(error.response);
  }
);

export default server;
