const cookie = {
  set: function (c_name, value, days = 90, hours = 24) {
    const expdate = new Date();
    expdate.setTime(expdate.getTime() + days * hours * 3600 * 1000); //90 days by default

    document.cookie =
      c_name +
      "=" +
      value +
      ";path=/" +
      ";expires=" +
      `${expdate.toUTCString()}`;
  },
  get: function (c_name) {
    if (document.cookie.length > 0) {
      let c_start = document.cookie.indexOf(c_name + "=");
      if (c_start !== -1) {
        c_start = c_start + c_name.length + 1;
        let c_end = document.cookie.indexOf(";", c_start);
        if (c_end === -1) c_end = document.cookie.length;
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  },
  delete: function (c_name, value) {
    const date = new Date();
    date.setTime(date.getTime());

    document.cookie =
      c_name + "=" + value + ";expires=" + date.toGMTString() + ";path=/";
  },
};

export default cookie;
