import { useState, useCallback, useEffect } from "react";
import { getUserInfo } from "src/api/api";
import cookie from "src/utils/cookie";

export const useAuth = () => {
  const [token, setToken] = useState(() => {
    return cookie.get("token") || null;
  });
  const [ready, setReady] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const login = useCallback((userData) => {
    cookie.set("token", userData.token);
    setToken(userData.token);
    setUserInfo(userData.data);

    sessionStorage.setItem("token", userData.token);
  }, []);

  const logout = useCallback(() => {
    cookie.delete("token", token);
    setToken(null);
    setUserInfo({});
  }, [token]);

  const getUserData = useCallback(async () => {
    try {
      const res = await getUserInfo();
      const userInfoData = res?.data?.data;

      setUserInfo(userInfoData);
    } catch (error) {}

    setReady(true);
  }, []);

  useEffect(() => {
    if (token) {
      getUserData();
    } else {
      setReady(true);
    }
  }, [getUserData, token]);

  return { login, logout, token, userInfo, ready };
};
