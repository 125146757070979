import server from "../utils/server";

// Account apis
export function getUserInfo() {
  return server({
    url: "/user",
    method: "get",
    params: {},
    datatype: "json",
  });
}
export function login_s(data) {
  return server({
    url: "/auth/login/cashback",
    method: "post",
    data: data,
    datatype: "json",
  });
}
export function signup_s(data) {
  return server({
    url: "/users",
    method: "post",
    data: { source: "cashbacklinx", ...data },
    datatype: "json",
  });
}

export function stores(data) {
  return server({
    url: "/stores/all",
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function sendReport(data) {
  return server({
    url: "/feedbacks",
    method: "post",
    data: data,
    datatype: "json",
  });
}

// reset pass apis
export function forgot(data) {
  return server({
    url: "/users/forgot",
    method: "post",
    data: data,
    datatype: "json",
  });
}
export function reset(data) {
  return server({
    url: "/users/reset",
    method: "post",
    data: data,
    datatype: "json",
  });
}

// cashback history
export function getCashbackOrder(data) {
  return server({
    url: "/user/orders/cashbacklinx",
    method: "get",
    params: data,
    datatype: "json",
  });
}

// payment history
export function getPaymentHistory(data) {
  return server({
    url: "/user/withdraws",
    method: "get",
    params: data,
    datatype: "json",
  });
}
export function getPaymentSettings(data) {
  return server({
    url: "/user/payments",
    method: "get",
    params: data,
    datatype: "json",
  });
}
export function setPayments(data) {
  return server({
    url: "/user/payments",
    method: "post",
    data: data,
    datatype: "json",
  });
}
export function updatePayments(data, id) {
  return server({
    url: `/user/payments/${id}`,
    method: "put",
    data: data,
    datatype: "json",
  });
}

// cashback trips
export function getCashbackTrips(data) {
  return server({
    url: "/user/trips/cashbacklinx",
    method: "get",
    params: data,
    datatype: "json",
  });
}

// cashback rate
export function getCashbackRate(data) {
  return server({
    url: "/user/rate/cashbacklinx",
    method: "get",
    params: data,
    datatype: "json",
  });
}

// amazon links apis
export function postLinks(data) {
  return server({
    url: "/user/links",
    method: "post",
    data: data,
    datatype: "json",
  });
}

export function getLinks(data) {
  return server({
    url: "/user/links",
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function putLinkInfo(id, data) {
  return server({
    url: `/user/links/${id}`,
    method: "put",
    data: data,
    datatype: "json",
  });
}

export function deleteLink(id) {
  return server({
    url: `/user/links/${id}`,
    method: "delete",
    data: {},
    datatype: "json",
  });
}

// amazon deals apis
export function createDeal(data) {
  return server({
    url: "/deals/user",
    method: "post",
    data: data,
    datatype: "json",
  });
}

export function getDeals(data) {
  return server({
    url: "/deals/user",
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function deleteDeal(id) {
  return server({
    url: `/deals/user/${id}`,
    method: "delete",
    data: {},
    datatype: "json",
  });
}

// price alert apis
export function getPriceAlert(data) {
  return server({
    url: `/cbl/dailyAlerts`,
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function postPriceAlert(data) {
  return server({
    url: `/cbl/dailyAlerts`,
    method: "post",
    data: data,
    datatype: "json",
  });
}

export function updatePriceAlert(id, data) {
  return server({
    url: `/dailyAlerts/${id} `,
    method: "put",
    data: data,
    datatype: "json",
  });
}

export function deletePriceAlert(id) {
  return server({
    url: `/dailyAlerts/${id}/delete `,
    method: "post",
    data: {},
    datatype: "json",
  });
}

export function openPriceAlert(id) {
  return server({
    url: `/dailyAlerts/${id}/open`,
    method: "post",
    data: {},
    datatype: "json",
  });
}

export function closePriceAlert(id) {
  return server({
    url: `/dailyAlerts/${id}/close`,
    method: "post",
    data: {},
    datatype: "json",
  });
}

export function getReceiverStatus() {
  return server({
    url: `/user/isReceiver`,
    method: "get",
    params: {},
    datatype: "json",
  });
}

export function blockSelf() {
  return server({
    url: `/user/blockSelf`,
    method: "post",
    data: {},
    datatype: "json",
  });
}

export function unblockSelf() {
  return server({
    url: `/user/unblockSelf`,
    method: "post",
    data: {},
    datatype: "json",
  });
}
