import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "src/context/AppContext";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { navLinkClasses } from "src/assets/styles/navigation";
import logo_small from "src/assets/img/logo_s.png";

const useStyles = makeStyles((theme) => {
  return {
    ...navLinkClasses(theme),
  };
});

const DrawerList = () => {
  const classes = useStyles();

  const { isAuthenticated, logout } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState(location?.pathname);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  const linkHanler = (link) => () => {
    setSelectedTab(link);
    setOpenDrawer(false);
    navigate(link);
  };

  const logOutHandler = (e) => {
    logout();

    navigate("/login");
  };

  const list = () => (
    <div className={classes.list} role="navigation">
      <>
        <List
          subheader={
            <ListSubheader component="div" className={classes.drawerHeader}>
              <img
                src={logo_small}
                alt="Cashback Links"
                style={{ width: "32px", height: "32px" }}
              />
              <IconButton onClick={toggleDrawer(false)}>
                <CloseIcon
                  style={{
                    fontSize: 24,
                  }}
                />
              </IconButton>
            </ListSubheader>
          }
        >
          {!isAuthenticated && (
            <ListItem
              button
              onClick={linkHanler("/login")}
              selected={selectedTab === "/login"}
            >
              <ListItemText primary={"Sign in"} />
            </ListItem>
          )}

          {isAuthenticated && (
            <>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={linkHanler("/")}
                  selected={selectedTab === "/"}
                >
                  <ListItemText primary="Generator" />
                </ListItem>
                <ListItem
                  button
                  onClick={linkHanler("/account/cashback-history")}
                  selected={selectedTab === "/account/cashback-history"}
                >
                  <ListItemText primary="Cashback History" />
                </ListItem>
                <ListItem
                  button
                  onClick={linkHanler("/account/shopping-trips")}
                  selected={selectedTab === "/account/shopping-trips"}
                >
                  <ListItemText primary="Shopping Trips" />
                </ListItem>
                <ListItem
                  button
                  onClick={linkHanler("/price-alert/my-price-alert")}
                  selected={selectedTab === "/price-alert/my-price-alert"}
                >
                  <ListItemText primary="Price Alert" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem button key={"Log out"} onClick={logOutHandler}>
                  <ListItemText primary={"Log out"} />
                </ListItem>
              </List>
            </>
          )}
        </List>
      </>
    </div>
  );

  return (
    <Hidden lgUp>
      <IconButton
        className="burger"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon style={{ fontSize: 30, color: "#606466" }} />
      </IconButton>

      <Drawer anchor={"right"} open={openDrawer} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </Hidden>
  );
};

export default DrawerList;
