const navLinkTemp = (theme) => {
  return {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 500,
    cursor: "pointer",
    margin: "0 15px",
  };
};

export const navLinkClasses = (theme) => {
  return {
    active: {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
      fontWeight: 600,
    },
    navLink: {
      ...navLinkTemp(theme),
      borderBottom: `2px solid transparent`,
      "&:hover": {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    navBtn: {
      ...navLinkTemp(theme),
      borderBottom: `2px solid transparent`,
    },

    authBtn: {
      ...navLinkTemp(theme),
      "&>a": {
        color: theme.palette.text.primary,
        borderBottom: `2px solid transparent`,
        "&:hover": {
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
      },
    },
    list: {
      width: 300,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    drawerHeader: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
  };
};
