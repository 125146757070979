import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { getPaymentSettings } from "src/api/api"
import cookie from "src/utils/cookie"

export const PaymentCheck = () => {
  const location = useLocation()
  const [showAlert, setShowAlert] = useState(false)
  const [paymentDefaultType, setPaymentDefaultType] = useState(null)

  useEffect(() => {
    const fetchPaymentSettings = async () => {
      const { data } = await getPaymentSettings()

      if (data?.data?.length > 0) {
        const defPayment = data.data.find((el) => el.isDefault === true)
        setPaymentDefaultType(defPayment.type)
      }
    }

    const isLogin = cookie.get("token")

    if (isLogin) {
      const isAlertPaymentChecked = localStorage.getItem(
        "isAlertPaymentChecked"
      )

      if (isAlertPaymentChecked && isAlertPaymentChecked === "true") return

      fetchPaymentSettings()
    } else {
      localStorage.setItem("isAlertPaymentChecked", "false")
    }
  }, [location])

  useEffect(() => {
    if (paymentDefaultType && invalidPaymentType.includes(paymentDefaultType)) {
      setShowAlert(true)
    }
  }, [paymentDefaultType])

  const handleClose = () => {
    setShowAlert(false)
    localStorage.setItem("isAlertPaymentChecked", "true")
  }

  return (
    <Dialog
      open={showAlert}
      onClose={handleClose}
      PaperProps={{ style: { padding: "20px", gap: "12px" } }}
    >
      <DialogTitle disableTypography style={{ padding: 0 }}>
        <Typography variant="h4" align="center" fontWeight="bold">
          Warning
        </Typography>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <DialogContentText variant="body1" align="center" style={{ margin: 0 }}>
          The payment method has been adjusted.
          <br />
          Please reset your payment method.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: 0 }}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleClose}
          style={{ borderRadius: "8px", textTransform: "none" }}
        >
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const invalidPaymentType = ["check", "zelle"]
