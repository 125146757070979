import { useState, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Hidden,
  Avatar,
  Box,
  Popper,
  Grow,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Paper,
  // Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { navLinkClasses } from "src/assets/styles/navigation";
import { useAppContext } from "src/context/AppContext";
import DrawerList from "./DrawerList";
import user_ava from "src/assets/img/user_def.png";
import logo from "src/assets/img/logo_b.png";
import logo_small from "src/assets/img/logo_s.png";

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      backgroundColor: "#fff",
      maxWidth: "100%",
      "& .MuiToolbar-root": {
        width: "100%",
      },
      "& .active": {
        borderBottom: `2px solid ${theme.palette.primary.main} !important`,
        fontWeight: 600,
      },
    },
    logo: {
      display: "flex",
      flexGrow: 1,

      "& img": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      },
    },
    avatar: {
      cursor: "pointer",
      margin: "0 16px",
      boxShadow: "0px 0px 3px 1px rgba(34, 60, 80, 0.1)",
    },
    ...navLinkClasses(theme),
  };
});

export default function Nav() {
  const classes = useStyles();

  const { isAuthenticated, logout, userInfo } = useAppContext();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    const link = event.target?.dataset?.link;
    if (!!link) navigate(link);
    setOpen(false);
  };

  const logOutHandler = () => {
    logout();
    setOpen(false);
    navigate("/login");
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <AppBar
      position="static"
      elevation={1}
      color="default"
      className={classes.appBar}
    >
      <Toolbar>
        <Box className={classes.logo}>
          <Box
            display="flex"
            alignItems="center"
            width={{ xs: 40, sm: 130 }}
            mr={4}
          >
            <Link to={"/"}>
              <Box component="picture">
                <source media="(max-width: 600px)" srcSet={logo_small} />
                <img src={logo} alt="Cashback Links" />
              </Box>
            </Link>
          </Box>
          {isAuthenticated && (
            <Hidden mdDown>
              <NavLink className={classes.navLink} to="/">
                Generator
              </NavLink>
              <NavLink
                className={classes.navLink}
                to="/account/cashback-history"
              >
                Cashback History
              </NavLink>
              <NavLink className={classes.navLink} to="/account/shopping-trips">
                Shopping Trips
              </NavLink>
              <NavLink
                className={classes.navLink}
                to="/price-alert/my-price-alert"
              >
                Price Alert
              </NavLink>
            </Hidden>
          )}
        </Box>

        {!isAuthenticated ? (
          <>
            <Hidden mdDown>
              <Box className={classes.authBtn}>
                {/* <NavLink to="/auth/sign-up">
                  Sign Up
                </NavLink>
                <Divider
                  orientation="vertical"
                  flexItem
                  light
                  style={{ marginLeft: 5, marginRight: 5 }}
                /> */}
                <NavLink to="/login">Sign in</NavLink>
              </Box>
            </Hidden>
          </>
        ) : (
          <Hidden mdDown>
            <Box display="flex" alignItems="center">
              <Avatar
                alt={userInfo?.email}
                src={userInfo?.avatar || user_ava}
                className={classes.avatar}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              />
              <Typography>{userInfo.email}</Typography>
            </Box>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={logOutHandler}>Sign Out</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Hidden>
        )}

        <DrawerList />
      </Toolbar>
    </AppBar>
  );
}
